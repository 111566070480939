body,
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: "Roboto", "Open Sans", Arial, sans-serif;
  height: 100%;
  min-height: 100%;
  /* word-break: break-word; */
}
.body-container,
#app,
#root {
  height: 100%;
  min-height: 100%;
}
a {
  color: #8dca80;
}
a:hover {
  color: #50554b;
  text-decoration: none;
}
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", "Roboto", "Open Sans", Arial, sans-serif !important;
  font-weight: 700 !important;
}

@media (max-width: 776px) {
  .row > div {
    margin-bottom: 2em;
  }
}

.clearfix:after {
  content: " "; /* Older browser do not support empty content */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
