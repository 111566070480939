.GDPR .footer-note {
  position: fixed;
  width: 100%;
  border: none;
  background-color: rgba(0, 0, 0, 0.85);
  bottom: 0;
  z-index: 7900;
  min-height: 50px;
  padding: 1.5em; }
  .GDPR .footer-note .alert-actions {
    float: right;
    text-align: right;
    width: 30%; }
    .GDPR .footer-note .alert-actions button {
      cursor: pointer;
      font-size: .9em; }
    .GDPR .footer-note .alert-actions .setting-button {
      border-color: #fff;
      color: #fff; }
  .GDPR .footer-note .alert-text {
    float: left;
    text-align: left;
    font-size: 11pt;
    line-height: 16pt;
    color: #fff !important;
    padding-bottom: 5px;
    width: 70%; }
    .GDPR .footer-note .alert-text .cookie-policy, .GDPR .footer-note .alert-text .privacy-policy {
      cursor: pointer;
      text-decoration: underline; }

.custom-overlay {
  z-index: 7901; }

.custom-modal {
  max-width: 400px; }
  .custom-modal h3 {
    font-size: 1.2em; }
  .custom-modal p {
    font-size: .8em; }
  .custom-modal ul {
    margin-left: 1em; }
    .custom-modal ul li {
      font-size: .9em; }
      .custom-modal ul li button {
        font-size: .9em; }
